@font-face {
  font-family: "Source Code Pro Medium";
  src: local('SourceCodePro-Medium.ttf'),
        url('SourceCodePro-Medium.ttf') format('truetype')
}  

.App {
  font-family: "Source Code Pro Medium", sans-serif;
  min-height: 100vh;
  text-align: center;
  color: white;
  /* background-image:linear-gradient(
    to top,
    rgb(38, 140, 153),
    rgba(38, 140, 153, 0.8) 20%,
    rgba(38, 140, 153, 0.6)), url("cool-background.png"); */
  background-size: cover;
  margin: 0;
}

.App-body {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.background {
  position: absolute;
  top: 60px;
  bottom: 60px;
  left: 0;
  z-index: -1;
  height:90% !important;
}

html,
body {
  margin: 0;
  padding: 0;
}
body {
  background-color: black !important;
}

.bi {
  color: white !important;
  font-family: "Source Code Pro Medium", sans-serif;
  text-shadow: 1px 1px grey;
}